<template>
  <div>
      <UsersTable />
  </div>
</template>

<script>
import UsersTable from '@/components/Dashboard/Users/UsersTable'
export default {
  components: {
    UsersTable
  }
}
</script>

<style>

</style>